import React, { useEffect, useRef, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion, useInView } from "framer-motion";
// contexts..
import RefContext from "../../Utils/RefContext";
import { CursorContext } from "../CustomCursor/CustomCursor";

const TypingText = () => {
  const targrtEl = useContext(RefContext);
  const { mouseEntering, mouseLeaving } = useContext(CursorContext);
  const animeViewRef = useRef(null);
  const targetRef = useRef(null);
  const isInView = useInView(animeViewRef, { amount: 0.5, once: true });

  const h2Variants = {
    initial: {
      y: -100,
      opacity: 0,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  // ONLY GOD KNOW'S HOW THIS CODE WORK...
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // ball parallex animation effect...
    gsap.to("[data-speed]", {
      y: (i, el) =>
        (1 - parseFloat(el.getAttribute("data-speed"))) *
        ScrollTrigger.maxScroll(window),
      scale: 1.5,
      ease: "power2.out",
      scrollTrigger: {        
        start: 0,
        end: "max",
        scrub: 1,
      },
    });



    // background color changing animation..

    // const tl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".article",
    //     start: "0 70%",
    //     end: "50% 50%",
    //     scrub: true,
    //     markers: false,
    //   },
    // });

    // tl.to(".article",{
    //   backgroundColor:"black"
    // })
  }, []);

  return (
    <article className="article" ref={targrtEl}>
      <motion.div
        ref={targetRef}
        transition={{ duration: 2, delay: 1.5 }}
        className="article-container custom_container container position-relative"
      >
        <Row>
          <Col sm={12} id="text-content">
            <div className="text-section" ref={animeViewRef}>
              <motion.h2
                className="typoghrphy-h2"
                onMouseEnter={mouseEntering}
                onMouseLeave={mouseLeaving}
                variants={h2Variants}
                animate={isInView ? "animate" : "initial"}
                transition={{ duration: 1, ease: "easeInOut" }}
                viewport={{ once: true }}
              >
                Fueling Success Through
              </motion.h2>
              <h3 className="typoghrphy-h3">“Innovative Solutions”</h3>              
            </div>
          </Col>
        </Row>
      </motion.div>
    </article>
  );
};

export default TypingText;

