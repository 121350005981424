import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import footerImg1 from "../../Assets/Images/Vector.png";
import footerLayer1 from "../../Assets/Images/footeLayer.png";
import footerlayer2 from "../../Assets/Images/footerLayer2.png";

const Footer = () => {
  const email = "tenvosolutions@gmail.com";
  const phoneNumber = "+91 9884018443";

  return (    
      <section>
        <div
          className="position-relative footer_main"
          id="contact"
          data-scroll-section
        >
          <footer
            className="py-5 custom_footer"
            style={{
              backgroundImage: `url(${footerImg1})`,
              backgroundPosition: "top",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Container>
              <Row>
                <Col sm={5} style={{ zIndex: "1" }}>
                  <h3 className="footer_head  mb-5 ">Contact Us</h3>
                  <div className="footer_content">
                    <span className="footer_span">
                      Tenvo Business Solutions Private Limited
                    </span>
                    <p className="mb-5">
                      STPI Technology Incubation Centre 2nd floor,SH 49A,
                      Tharamani, Chennai,Tamil Nadu – 600117
                    </p>
                    <a href={`mailto:${email}`} className="mb-4">
                      <span className="tenvo_contacts footer_span ">
                        {email}
                      </span>
                    </a>
                    <a href={`tel:${phoneNumber}`} className="mb-5">
                      <span className="tenvo_contacts footer_span">
                        {phoneNumber}
                      </span>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>

          {/* bottom footer */}
          <section className="bottom_footer">
            <Container className="p-3">
              <Col sm={12}>
                <span className="copyright-text">
                  ©2023 Copyright Tenvo Business Solutions Private Limited. All
                  Rights Reserved
                </span>
              </Col>
            </Container>
          </section>

          {/* layer2 image */}
          <div className="layer2">
            <img src={footerlayer2} alt="" />
          </div>
          {/* layer1 image */}
          <div className="layer1">
            <img src={footerLayer1} alt="" />
          </div>
        </div>
      </section>    
  );
};

export default Footer;
