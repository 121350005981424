import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import newLogo from "../../Assets/Images/navLogo.png";

import "./Navbar.css";


const Navbar = () => {
  // framer motion variants..
  const navBarVariants = {
    hidden: {
      y: "-100",
    },
    show: {
      y: 0,
    },
  };

  return (
    <Container className="py-2 hearder-vav">
      <motion.nav
        variants={navBarVariants}
        initial="hidden"
        animate="show"
        transition={{ duration: 1, delay: 3, ease: "easeIn" }}
      >
        <div className="brand_log">
          <Link smooth to="#home">
            <img src={newLogo} alt="Tenvo_logo" className="brand_image" />
            <span className="brand_name">
              tenv<motion.span>o</motion.span>
            </span>
          </Link>
        </div>

        <ul className="nav_ul">
          <li className="nav_list ">
            <Link smooth to="#service">
              Services
            </Link>
          </li>
          <li className="nav_list">
            <Link smooth to="#contact">
              Contact
            </Link>
          </li>
        </ul>
      </motion.nav>
    </Container>
  );
};

export default Navbar;
