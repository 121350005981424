import React, { useEffect, useRef, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { gsap } from "gsap";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import { CursorContext } from "../../Sections/CustomCursor/CustomCursor";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const LeftsideBox = ({
  imgPath,
  title,
  description,
  styles,
}) => {
  
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const imageRef = useRef(null);
  const outerBox = useRef(null);
  const framerRef = useRef(null);
  const isInView = useInView(framerRef, { amount: 0.5, once: true });

  // creating useContex instance and destructuring ..
  const { mouseEntering, mouseLeaving } = useContext(CursorContext);

  // framer variants.
  const anime = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

 

  useEffect(() => {
    const textElement = textRef.current;
    const imageEL = imageRef.current;
    const outerEL = outerBox.current;
    if (!textElement) return;
    if (!imageEL) return;
    if (!outerEL) return;

    // image color changing animation..
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: [imageEL, outerEL],
        start: "top 78%",
        end: "center 50%",
        scrub: 1,
        markers: false,
        once: true,
      },
    });
    tl2.fromTo(
      [imageEL, outerEL],
      { filter: "grayScale(100%)" },
      { filter: "grayScale(0%)" }
    );
  }, []);

  return (
    <motion.Container
      className="viewPort-container px-md-4 "
      ref={containerRef}      
    >
      <Row className="section_wraper mb-5  ">
        <Col
          sm={12}
          md={6}
          lg={5}
          className="left-box-container mb-sm-5 mb-md-0 mb-lg-0 mb-xl-0 d-flex justify-content-md-start justify-content-center justify-content-lg-center"
        >
          <div>
            <div ref={outerBox} className="inside_box_1" style={styles}>
              <motion.div className="inside_box_2">
                <motion.img ref={imageRef} src={imgPath} alt="" />
              </motion.div>
            </div>
          </div>
        </Col>

        <Col
          ref={textRef}
          sm={12}
          md={6}
          lg={6}
          className="d-flex align-items-center mt-4 mt-md-0 mt-lg-0 align-items-md-center align-items-lg-start justify-content-center flex-column"
        >
          <motion.div
            id="triggerElem"       
          >
            <motion.h1
              onMouseEnter={mouseEntering}
              onMouseLeave={mouseLeaving}
              className="heading mb-lg-4 text-md-start "             
            >
              {title}
            </motion.h1>
            <motion.div
              ref={framerRef}
              className="banner-p-wrapper text-center text-md-start text-lg-start mb-5 mb-sm-0 px-1"
              variants={anime}
              initial="hidden"
              whileInView={isInView ? "visible" : "hidden"}
              transition={{ duration: 0.5, ease: "easeOut" }}
              viewport={{ once: true }}
            >
              <motion.span className="reused-p mt-4">{description}</motion.span>
            </motion.div>
          </motion.div>
        </Col>
      </Row>
    </motion.Container>
  );
};

export default LeftsideBox;


