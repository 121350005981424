import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home/Home";
import ServiceSection from "./Sections/Servicesection/ServiceSection";
import Footer from "./Components/Footer/Footer";
import LocomotiveScroll from 'locomotive-scroll';




function App() {
  const locomotiveScroll = new LocomotiveScroll({
    lenisOptions: {
      wrapper: window,
      content: document.documentElement,      
      lerp: 0.1,
      duration: 1.2,
      orientation: 'vertical',
      gestureOrientation: 'vertical',
      smoothWheel: true,
      smoothTouch: false,
      wheelMultiplier: 1,
      touchMultiplier: 2,
      normalizeWheel: true,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
  },
  });
  
  return (

    // <section>
    //   <main className="scrollMain"><h1>SmmothScroll</h1></main>
    //   <main className="scrollMain"><h1>SmmothScroll</h1></main>
    //   <main className="scrollMain"><h1>SmmothScroll</h1></main>
    //   <main className="scrollMain"><h1>SmmothScroll</h1></main>
    // </section>
    
      <BrowserRouter>      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<ServiceSection />} />
          <Route path="/contact" element={<Footer />} />
        </Routes>
      </BrowserRouter>
    
  );
}

export default App;
