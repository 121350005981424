import React, { useRef, useEffect, useContext } from "react";
import { motion, useInView } from "framer-motion";
import { gsap } from "gsap";
import RefContext from "../../Utils/RefContext";
// import { CursorContext } from "../CustomCursor/CustomCursor";

const Banner = () => {
  const { bannerRef } = useContext(RefContext);
  const buttonRef = useRef(null);
  const targetRef = useRef(null);
  const isInView = useInView(targetRef, { once: true });
  const isButtonView = useInView(buttonRef, { once: true });

  // not using currently..
  // if you want animate cursor animation in this particular section u have to uncomment the CursorContext import and call the destructerd function inside the onMouseEnter,onMouseLeave attribute depends on your need
  // const { mouseEntering, mouseLeaving } = useContext(CursorContext);

  // main animation variant for the row..
  const newAnimation = {
    initial: {
      opacity: 0,
      y: "50%",
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  // scrolldown button animation..
  const buttonAnimation = {
    zoomOut: {
      width: "30%",
      height: "64px",
    },
    zoomIn: {
      width: "100%",
      height: "64px",
    },
  };

  useEffect(() => {
    // gsap animation for top rounded div..
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#banner-top",
        start: "0 70%",
        end: "50% 50%",
        scrub: true,
        markers: false,
      },
    });

    tl.to(".rounded-div-wrapper", {
      height: 0,
      marginTop: 0,
      ease: "expo.inOut",
    });
  }, []);

  // JUMB TO SERVICE SECTION ,FUNCTION..
  const scrollTOSection = () => {
    window.scrollTo({
      top: bannerRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <motion.section className="banner_section article " id="banner-top">
      <div className="rounded-div-wrapper">
        <div className="rounded-div" />
      </div>
      <section className="d-flex align-items-center justify-content-center  w-100 h-100 change-bg">
        <motion.div className=" p-lg-4 container">
          <motion.div
            className=" row p-lg-5 overflow-hidden banner-texts flex-column align-items-center justify-content-center"
            variants={newAnimation}
            initial="initial"
            whileInView={isInView ? "animate" : "initial"}
            transition={{ duration: 1, ease: "easeInOut" }}
            viewport={{ once: true }}
          >
            <motion.h1 ref={targetRef} className="text-center">
              We provide grate
            </motion.h1>
            <motion.h1 className="text-center">solutions & ideas!</motion.h1>
            <span className="text-center mt-4 description-span">
              <span className="tenvo-span">Tenvo</span>, a trailblazer in mobile
              and web app development, pioneers with AI, Deep Learning, and
              Machine Learning. Our tech hub crafts seamless solutions,
              redefining possibilities in the digital landscape.
            </span>
            <div className="button-wrapper mt-5">
              <motion.button
                ref={buttonRef}
                variants={buttonAnimation}
                initial="zoomIn"
                animate={isButtonView ? "zoomIn" : "zoomOut"}
                transition={{ duration: 0.7, ease: "easeIn" }}
                viewport={{ once: true }}
                className="lb-btn "
                onClick={scrollTOSection}
              >
                <motion.span
                  className="inside-btn"
                  initial={{ opacity: 0, y: "-100%" }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, ease: "easeIn" }}
                >
                  Learn more about our services
                </motion.span>
                <motion.svg
                  initial={{ opacity: 0, y: "100%" }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, ease: "easeIn" }}
                  viewport={{ once: true }}
                  width="34px"
                  height="34px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 17L17 7M17 7H8M17 7V16"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </motion.svg>
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      </section>
    </motion.section>
  );
};
export { Banner };
