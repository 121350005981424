import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import CustomProvider from "./Sections/CustomCursor/CustomCursor";

// bootstrap stylesheet..
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CustomProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </CustomProvider>
);
