import React, { useState, useEffect, createContext, Ref, useRef } from "react";
import gsap from "gsap";
export const CursorContext = createContext();

const CustomProvider = ({ children }) => {
  const cursorRef = useRef(null);
  const [cursorClass, setCursorClass] = useState(false);

  // CURSOR SIZE
  // for mouseEntering..
  const animateSize = {
    width: 120,
    height: 120,
  };

  // CURSOR SIZE
  // for mouseLeaving..
  const defaultSize = {
    width: 16,
    height: 16,
  };

  // mouseEntering function...
  const mouseEntering = () => {
    gsap.to(cursorRef.current, {
      width: animateSize.width,
      height: animateSize.height,
      duration: 0.3,
      background: "white",
      mixBlendMode: "difference",
      ease: "Power2.easeOut",
    });
  };

  // mouseLeaving function...
  const mouseLeaving = () => {
    gsap.to(cursorRef.current, {
      width: defaultSize.width,
      height: defaultSize.height,
      duration: 0.3,
      background: "black",
      mixBlendMode: "normal",
      ease: "Power2.easeOut",
    });
  };

  useEffect(() => {
    const cursor = cursorRef.current;

    //cursor following function..
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      gsap.to(cursor, {
        duration: 0.3,
        x: clientX - 20,
        y: clientY - 20,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <CursorContext.Provider
      value={{ cursorRef, mouseEntering, mouseLeaving, cursorClass }}
    >
      {children}
    </CursorContext.Provider>
  );
};

export default CustomProvider;

