import React, {  useRef,  useContext } from "react";
import LandingPage from "../../Sections/LandingPage/LandingPage";
import TypingText from "../../Sections/TypingText/TypingText";
import ServiceSection from "../../Sections/Servicesection/ServiceSection";
import { Banner } from "../../Sections/Banner/Banner";
import Footer from "../Footer/Footer";
import RefContext from "../../Utils/RefContext";
import Headroom from "react-headroom";
import Navbar from "../Navbar/Navbar";


// cursor context
import { CursorContext } from "../../Sections/CustomCursor/CustomCursor";

const Home = ({ children }) => {
  const targetRef = useRef(null);
  const bannerRef=useRef(null);
  const { cursorRef, cursorClass } = useContext(CursorContext);

  return (
    <RefContext.Provider value={{targetRef,bannerRef}}>
      <main id="home" className="main-container position-relative">
        <Headroom style={{ zIndex: 100, height: "70px" }}>
          <Navbar />
        </Headroom>
        <section id="home-section">
          <LandingPage />
          <TypingText />
          <Banner />
          <ServiceSection />
          <Footer />
        </section>
        <div
          ref={cursorRef}
          className={`custom-cursor ${cursorClass ? "" : ""}`}
        />
      </main>
    </RefContext.Provider>
  );
};

export default Home;
