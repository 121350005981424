import React, { useEffect, useState, useContext, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import RefContext from "../../Utils/RefContext";
import { CursorContext } from "../CustomCursor/CustomCursor";
import "./Landingpage.css";

const LandingPage = () => {
  const [btnAnimation, setBtnAnimation] = useState(false);
  const typingEectionEL = useContext(RefContext);
  const { mouseLeaving, mouseEntering } = useContext(CursorContext);
  const targetRef = useRef(null);

  // TYPING SECTION SCROLL BUTTON FUNCTION..
  const scrollTOSection = () => {
    window.scrollTo({
      top: typingEectionEL.current.offsetTop,
      behavior: "smooth",
    });
  };

  const intialAnimation = {
    start: {
      scale: 2,
      opacity: 0,
      x: "0px",
    },
    end: {
      scale: 1,
      x: "0px",
      opacity: 1,
      ease: "easeInOut",
    },
  };

  useEffect(() => {
    // setting shake class to scrollButton..
    const addClassTimeout = setTimeout(() => {
      setBtnAnimation(true);
    }, 4000);
    // removing shake class form scrollButton..
    const removeClassTimeout = setTimeout(() => {
      setBtnAnimation(false);
    }, 6000);

    return () => {
      // tl.kill();
      clearTimeout(addClassTimeout);
      clearTimeout(removeClassTimeout);
    };
  }, []);

  return (
    <>
      <section
        id="section_wrapers"
        className="section_wrapers positoin-relative"        
      >
        {/*  bubles background */}
        <div class="area">
          <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>

        {/* landing page contents */}
        <Container className="landig-conteiner ">
          <Row>
            <Col
              sm={12}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <motion.h1
                className="titles mb-0 "
                id="firstTitle"
                variants={intialAnimation}
                initial="start"
                animate="end"
                transition={{ duration: 3 }}
                data-scroll
                data-scroll-speed=".3"
              >
                <motion.span className="hover_span-1">
                  <div className="demo-div-1">Connect,</div>
                  <div className="demo-div-1">Connect,</div>
                </motion.span>
                <motion.span className="hover_span-2">
                  <div className="demo-div-2">Create,</div>
                  <div className="demo-div-2">Create,</div>
                </motion.span>
                <motion.span className="hover_span-3">
                  <div className="demo-div-3">Catalyze:</div>
                  <div className="demo-div-3">Catalyze:</div>
                </motion.span>
              </motion.h1>
              <motion.h1
                // onMouseEnter={mouseEntering}
                // onMouseLeave={mouseLeaving}
                className="titles mb-0"
                variants={intialAnimation}
                initial="start"
                animate="end"
                transition={{ duration: 3 }}
                data-scroll
                data-scroll-speed=".3"
              >
                Pioneering Digital Synergy
              </motion.h1>
              <motion.h1
                onMouseEnter={mouseEntering}
                onMouseLeave={mouseLeaving}
                className="titles mb-0"
                variants={intialAnimation}
                initial="start"
                animate="end"
                transition={{ duration: 3 }}
                ref={targetRef}
                data-scroll
                data-scroll-speed=".3"
              >
                {" "}
                for Tech Ventures
              </motion.h1>
            </Col>
          </Row>
        </Container>
        
        {/* scroll down button */}
        <motion.button
          className="scroll-down"
          onClick={scrollTOSection}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 2, delay: 3.3 }}
        >
          <motion.svg
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1.5, delay: 3.7, ease: "easeInOut" }}
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="18"
            viewBox="0 0 37 18"
            fill="none"
          >
            <path
              d="M35.8551 0.501485C35.6306 0.285161 35.3274 0.163806 35.0115 0.163806C34.6955 0.163806 34.3923 0.285161 34.1679 0.501485L19.4419 14.7935C19.2914 14.9427 19.1117 15.0609 18.9134 15.1413C18.7151 15.2217 18.5023 15.2626 18.2875 15.2615C18.0716 15.2619 17.8579 15.2208 17.6585 15.1405C17.4591 15.0601 17.278 14.9422 17.1257 14.7935L2.40706 0.501485C2.18225 0.285556 1.87935 0.164053 1.56346 0.163086C1.24531 0.163706 0.93998 0.285121 0.712458 0.501485C0.489665 0.721119 0.364746 1.01729 0.364746 1.32589C0.364746 1.63448 0.489665 1.93065 0.712458 2.15029L15.4385 16.4423C16.1955 17.1748 17.2198 17.586 18.2875 17.586C19.3551 17.586 20.3794 17.1748 21.1365 16.4423L35.8551 2.15029C36.0796 1.93157 36.2058 1.63505 36.2058 1.32589C36.2058 1.01672 36.0796 0.720198 35.8551 0.501485Z"
              fill="white"
            />
          </motion.svg>
        </motion.button>
      </section>
    </>
  );
};

export default LandingPage;

// backup
{
  /* <motion.h1                
className="titles mb-0 "
variants={intialAnimation}
initial="start"
animate="end"
transition={{ duration: 3 }}  
data-scroll
data-scroll-speed='-.4'
data-scroll-direction='vertical'              
>
<motion.span className="hover_span">Connect,</motion.span>
<motion.span className="hover_span">Create,</motion.span>
<motion.span className="hover_span">Catalyze:</motion.span>
</motion.h1>
<motion.h1
onMouseEnter={mouseEntering}
onMouseLeave={mouseLeaving}
className="titles mb-0"
variants={intialAnimation}
initial="start"
animate="end"
transition={{ duration: 3 }}
data-scroll
data-scroll-speed='-.4'
data-scroll-direction='vertical' 
>
<motion.span>Pioneering </motion.span>
Digital Synergy
</motion.h1>
<motion.h1
onMouseEnter={mouseEntering}
onMouseLeave={mouseLeaving}
className="titles mb-0"
variants={intialAnimation}
initial="start"
animate="end"
transition={{ duration: 3 }}
data-scroll
data-scroll-speed='-.4'
data-scroll-direction='vertical' 
ref={targetRef}
>
{" "}
for Tech
<motion.span> Ventures</motion.span>
</motion.h1> */
}
