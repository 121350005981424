import React, { useRef, useContext, useEffect } from "react";
import LeftsideBox from "../../Reusable/LeftsideBox/LeftsideBox";
import RightsideBox from "../../Reusable/RightsideBox/RightsideBox";

// images..
import Image1 from "../../Assets/Images/leftIMG1.png";
import Image2 from "../../Assets/Images/rightIMG1.png";
import Image3 from "../../Assets/Images/leftIMG2.png";
import Image4 from "../../Assets/Images/rightIMG2.png";
import Image5 from "../../Assets/Images/leftIMG3.png";
import Image6 from "../../Assets/Images/rightIMG3.png";
// framer motion....
import { motion } from "framer-motion";
import RefContext from "../../Utils/RefContext";

const ServiceSection = ({}) => {
  
  const { bannerRef } = useContext(RefContext);
  // passing images and background-colors to the child components...
  const style1 = {
    background:
      "linear-gradient(180deg, #6C9EBB 0%, rgba(217, 217, 217, 0.00) 100%)",
  };
  const style2 = {
    background:
      "linear-gradient(335deg, #00BBD4 5.06%, rgba(22, 105, 156, 0.00) 36.68%)",
  };
  const style3 = {
    background:
      "linear-gradient(180deg, #48EAC1 0%, rgba(217, 217, 217, 0.00) 100%)",
  };
  const style4 = {
    background:
      "linear-gradient(335deg, #F9A756 5.06%, rgba(251, 150, 30, 0.04) 36.68%)",
  };
  const style5 = {
    background:
      " linear-gradient(180deg, #D2D2D2 0%, rgba(217, 217, 217, 0.00) 100%)",
  };
  const style6 = {
    background:
      "linear-gradient(335deg, #59C6FF 5.06%, rgba(34, 105, 174, 0.15) 36.68%)",
  };

  return (
    <main data-scroll-sectionl ref={bannerRef}>
      <section className="service-section container mt-5 mb-5" id="service">
        <div className="d-flex justify-content-center mb-4">
          <motion.h1
            className="title_1"
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, ease: "easeInOut" }}
            viewport={{ once: true }}
          >
            What
            <span className="title_span ms-lg-4 ms-2">we do </span>
            <motion.span
              initial={{ x: 60, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeInOut" }}
              viewport={{ once: false }}
              className="title_span"
            >
              ?
            </motion.span>
          </motion.h1>
        </div>
        <LeftsideBox
          imgPath={Image1}
          styles={style1}
          title="Cross Platform App Development"
          description="By releasing the application on multiple platforms we ensure that the reach is not limited but has the potential to reach your target audience. This is crucial in giving users and clients access to your applications on more than one platform."
        />
        <RightsideBox
          imgPath={Image2}
          styles={style2}
          title="End to End Solutions"
          description="You have an idea to implement, you have done half the work so let us take care of the rest. Our team consults and prepares Plan of Action as per your needs so that we create value for our clients and help them achieve their full potential."
        />
        <LeftsideBox
          imgPath={Image3}
          styles={style3}
          title="Design & User Experience"
          description="Our focus is also on how a user interacts with the system. We make use of case studies, info graphics, and other elements of design to make yours a truly remarkable one. With a great sense of feel and experience, we will make your audience mesmerize."
        />
        <RightsideBox
          imgPath={Image4}
          styles={style4}
          title="Maintenance & App monitoring"
          description="We do comprehensive back end solutions. Our team is prepared and experienced in various degrees of scalability of usage and traffic. You can put your development needs on our backs, and rest assured we will hold it."
        />
        <LeftsideBox
          imgPath={Image5}
          styles={style5}
          title="Backend and API development"
          description="We do comprehensive back end solutions. Our team is prepared and experienced in various degrees of scalability of usage and traffic. You can put your development needs on our backs, and rest assured we will hold it."
        />
        <RightsideBox
          imgPath={Image6}
          styles={style6}
          title="Cloud Service"
          description="Today almost all multinationals and many organizations have and are making rapid shifts with cloud based solutions. Organisations need best-in-class global infrastructure and tools to make this possible and global reach to support your cloud needs, wherever your business takes you. We help you prep for the future."
        />
      </section>
    </main>
  );
};

export default ServiceSection;

