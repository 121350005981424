import React, { useEffect, useRef, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { gsap } from "gsap";
import { motion, useInView, useTransform, useScroll } from "framer-motion";
import { CursorContext } from "../../Sections/CustomCursor/CustomCursor";

const RightsideBox = ({ imgPath, title, description, styles, targetScale }) => {
  const textRef2 = useRef(null);
  const containerRef = useRef(null);
  const imageEl = useRef(null);
  const outerBoxRef = useRef(null);
  const viewRef = useRef(null);
  const isInView = useInView(viewRef, { amount: 0.5, once: true });
  const { mouseEntering, mouseLeaving } = useContext(CursorContext);

  useEffect(() => {
    const textElement = textRef2.current;
    const imageItem = imageEl.current;
    const outerBoxEL = outerBoxRef.current;

    if (!textElement) return;
    if (!imageItem);
    if (!outerBoxRef);

    // image animation..
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: [imageItem, outerBoxEL],
        start: "top 78%",        
        end: "center 50%",
        scrub: 1,
        markers: false,
        once:true
      },
    });

    tl2.fromTo(
      [imageItem, outerBoxEL],
      { filter: " grayscale(100%)" },
      { filter: " grayscale(0%)" }
    );
  }, []);

  // framer variants.
  const anime = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <motion.Container
      className="viewPort-container px-md-4"
      ref={containerRef}

    >
      <Row className="section_wraper mb-4 flex-column-reverse flex-lg-row flex-md-row justify-content-lg-end ">
        <Col
          sm={12}
          md={6}
          lg={6}
          className="d-flex align-items-center  align-items-md-start align-items-lg-start justify-content-center flex-column"
        >
          <motion.div ref={textRef2}>
            <motion.h4
              onMouseEnter={mouseEntering}
              onMouseLeave={mouseLeaving}
              className="heading mb-lg-4 text-md-start"
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,

                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: true }}
            >
              {title}
            </motion.h4>
            <motion.div
              className="banner-p-wrapper text-center text-md-start text-lg-start"
              ref={viewRef}
              variants={anime}
              initial="hidden"
              whileInView={isInView ? "visible" : "hidden"}
              transition={{ duration: 0.5, ease: "easeOut" }}
              viewport={{ once: true }}
            >
              <motion.span className=" reused-p mt-lg-4  mb-5 mb-md-0 mb-lg-0 mb-xl-0">
                {description}
              </motion.span>
            </motion.div>
          </motion.div>
        </Col>
        <Col
          sm={12}
          md={6}
          lg={5}
          className="d-flex align-items-center justify-content-center justify-content-lg-center justify-content-xxl-center justify-content-md-end mb-4"
        >
          <div>
            <div ref={outerBoxRef} className="rght_box" style={styles}>
              <div className="rght_box_inside">
                <motion.img ref={imageEl} src={imgPath} alt="" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </motion.Container>
  );
};

export default RightsideBox;
